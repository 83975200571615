<template>
  <div style="width: 100vw;">
    <slot />
  </div>
</template>

<script>
export default {
  name: "AuthLayout"
}
</script>

<style lang="scss">
  @import "../assets/main.scss";
</style>
